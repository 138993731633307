// https://formvalidation.io/

const bookingFields = {
  first_or_last_name: {
    selector: '.js-generic-name',
    validators: {
      notEmpty: {
      },
      regexp: {
        regexp: /^[a-z\s\']+$/i,
        message: 'Il nome può contenere solo caratteri alfabetici e spazi'
      }
    }
  },
  email: {
    selector: '.js-email-address',
    validators: {
      notEmpty: {
      },
      emailAddress: {
      },
    }
  },
  confirmEmail: {
    selector: '.js-confirm-email-address',
    validators: {
      notEmpty: {},
      emailAddress: {},
      identical: {
        compare: function() {
          return element.querySelector('[name="guest[email]"]').value;
        }
      }
    }
  },
  residence: {
    selector: '.js-residence',
    validators: {
      notEmpty: {
      },
    }
  },
  not_empty_select: {
    selector: '.not-empty-sel',
    validators: {
      notEmpty: {
      }
    }
  },
  phone: {
    selector: '.js-phone-number',
    validators: {
      notEmpty: {
      },
      regexp: {
        regexp: /^[0-9\s\+]+$/i,
        message: 'Il numero di telefono può contenere solo spazi, valori numerici e il segno +'
      }
    }
  },
  fiscal_code: {
    selector: '.js-fiscal-code',
    validators: {
      notEmpty: {
      },
      regexp: {
        regexp: /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
        message: 'Il codice fiscale non ha un formato corretto'
      }
    }
  },
  checkbox_mandatory_1: {
    selector: '.checkbox-mandatory-1',
    validators: {
      choice: {
        min: 1,
        max: 1,
        message: 'Per completare la procedura occorre spuntare la voce'
      }
    }
  },
  checkbox_mandatory_2: {
    selector: '.checkbox-mandatory-2',
    validators: {
      choice: {
        min: 1,
        max: 1,
        message: 'Per completare la procedura occorre spuntare la voce'
      }
    }
  },
  checkbox_mandatory_3: {
    selector: '.checkbox-mandatory-3',
    validators: {
      choice: {
        min: 1,
        max: 1,
        message: 'Per completare la procedura occorre spuntare la voce'
      }
    }
  },
  checkbox_mandatory_4: {
    selector: '.checkbox-mandatory-4',
    validators: {
      choice: {
        min: 1,
        max: 1,
        message: 'Per completare la procedura occorre spuntare la voce'
      }
    }
  }
}

window.formValidationAttributes1 = (element) => {
  return {
    // Set the default locale
    locale: getLocale(),
    localization: FormValidation.locales[getLocale()],
    fields: bookingFields,
    plugins: {
      trigger: new FormValidation.plugins.Trigger(),
      autoFocus: new FormValidation.plugins.AutoFocus(),
      submitButton: new FormValidation.plugins.SubmitButton(),   // Validate fields when clicking the Submit button
      defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Submit the form when all fields are valid
      declarative: new FormValidation.plugins.Declarative({html5Input: true}),
      bulma: new FormValidation.plugins.Bulma(),
      icon: new FormValidation.plugins.Icon({
        valid: 'fa fa-check',
        invalid: 'fa fa-times',
        validating: 'fa fa-refresh',
      }),
    }
  }
}

// same of formValidationAttributes1 except submitButton and defaultSubmit plugins
window.formValidationAttributesWSignature = (element) => {
  return {
    // Set the default locale
    locale: getLocale(),
    localization: FormValidation.locales[getLocale()],
    fields: bookingFields,
    plugins: {
      trigger: new FormValidation.plugins.Trigger(),
      autoFocus: new FormValidation.plugins.AutoFocus(),
      bulma: new FormValidation.plugins.Bulma(),
      icon: new FormValidation.plugins.Icon({
        valid: 'fa fa-check',
        invalid: 'fa fa-times',
        validating: 'fa fa-refresh',
      }),
    }
  }
}


window.formValidationAttributesLite = (element) => {
  return {
    // Set the default locale
    locale: getLocale(),
    localization: FormValidation.locales[getLocale()],
    fields: {
      email: {
        selector: '.js-email-address',
        validators: {
          notEmpty: {
          },
          emailAddress: {
          },        }
      },
      confirmEmail: {
        selector: '.js-confirm-email-address',
        validators: {
          identical: {
            compare: function() {
              return element.querySelector('[name="guestx[email]"]').value;
            }
          },
          notEmpty: {}
        }
      },
      first_or_last_name: {
        selector: '.js-generic-name',
        validators: {
          notEmpty: {
          },
          regexp: {
            regexp: /^[a-z\s\']+$/i,
          }
        }
      },
    },
    plugins: {
      trigger: new FormValidation.plugins.Trigger(),
      autoFocus: new FormValidation.plugins.AutoFocus(),
      // submitButton: new FormValidation.plugins.SubmitButton(),   // Validate fields when clicking the Submit button (è questo che interferisce con submit ajax!)
      // defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Submit the form when all fields are valid (se tolgo non fa submit)
      bulma: new FormValidation.plugins.Bulma(),
      icon: new FormValidation.plugins.Icon({
        valid: 'fa fa-check',
        invalid: 'fa fa-times',
        validating: 'fa fa-refresh',
      }),
    }
  }
}




window.formValidationAttributesParticipants = (element) => {
  return {
    // Set the default locale
    locale: getLocale(),
    localization: FormValidation.locales[getLocale()],
    fields: {
      email: {
        selector: '.js-email-address',
        validators: {
          notEmpty: {
          },
          emailAddress: {
          },        }
      },
      confirmEmail: {
        selector: '.js-confirm-email-address',
        validators: {
          identical: {
            compare: function() {
              return element.querySelector('[name="guestx[email]"]').value;
            }
          },
          notEmpty: {}
        }
      },
      first_or_last_name: {
        selector: '.js-generic-name',
        validators: {
          notEmpty: {
          },
          regexp: {
            regexp: /^[a-z\s\']+$/i,
          }
        }
      },
    },
    plugins: {
      trigger: new FormValidation.plugins.Trigger(),
      autoFocus: new FormValidation.plugins.AutoFocus(),
      submitButton: new FormValidation.plugins.SubmitButton(),   // Validate fields when clicking the Submit button (è questo che interferisce con submit ajax!)
      defaultSubmit: new FormValidation.plugins.DefaultSubmit(), // Submit the form when all fields are valid (se tolgo non fa submit)
      bulma: new FormValidation.plugins.Bulma(),
      icon: new FormValidation.plugins.Icon({
        valid: 'fa fa-check',
        invalid: 'fa fa-times',
        validating: 'fa fa-refresh',
      }),
    }
  }
}

    // const element = document.getElementById("")
    // return FormValidation.formValidation(element, formValidationAttributes2(el));

// FormValidation.formValidation(document.getElementById(""), formValidationAttributes2(el))

if (!window.validateForm) {
  // console.log('validate form...');
  window.validateForm = (elementID) => {
    const element = document.getElementById(elementID)
    return FormValidation.formValidation(element, formValidationAttributes1(element));
  }
} else {
  console.log('testaaaa.....');
}


if (!window.validateFormLite) {
  // console.log('validate form...');
  window.validateFormLite = (elementID) => {
    const element = document.getElementById(elementID)
    return FormValidation.formValidation(element, formValidationAttributesLite(element));
  }
} else {
  console.log('testaaaa.....');
}





if (!window.enableCopyButton) {
  window.enableCopyButton = (fv=null, buttonsSelectors='.copy-users-data') => {
    var buttons = document.querySelectorAll(buttonsSelectors);
    buttons.forEach(function(button) {
      const url = button.dataset.url;
      button.addEventListener('click', function () {
        const request = new Request(url);
        fetch(request)
          .then(response => response.json())
          .then(data => {
            let sel_base = button.dataset.destination
            let e
            document.getElementById(sel_base+"_email").value = data.email;
            if (e=document.getElementById(sel_base+"_email")) { e.value = data.email }
            if (e=document.getElementById(sel_base+"_first_name")) { e.value = data.first_name }
            if (e=document.getElementById(sel_base+"_last_name")) { e.value = data.last_name }
            if (e=document.getElementById(sel_base+"_phone")) { e.value = data.phone }
            if (e=document.getElementById(sel_base+"_fiscal_code")) { e.value = data.fiscal_code }
            if (e=document.getElementById(sel_base+"_residence")) { e.value = data.residence }
            const dob = new Date(data.date_of_birth)
            if (e=document.querySelector('.ror-date-select.ror-date-select-year')) { e.value = dob.getFullYear() }
            if (e=document.querySelector('.ror-date-select.ror-date-select-month')) { e.value = dob.getMonth()+1 }
            if (e=document.querySelector('.ror-date-select.ror-date-select-day')) { e.value = dob.getDate() }

            if (fv) {
              fv.revalidateField('email');
              fv.revalidateField('first_or_last_name');
              fv.revalidateField('residence');
              fv.revalidateField('phone');
              fv.revalidateField('fiscal_code');
            }
          })
          .catch(error => {
            // Handle error
          });
      });
    });

  }
}

