// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
window.Rails = Rails
Rails.start();

// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("@fortawesome/fontawesome-free/js/all")
require("@fortawesome/fontawesome-free/css/all")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


// import feather from "feather-icons"
// window.feather = feather
// document.addEventListener("turbolinks:load", () => {
//   feather.replace();
// })


import '../stylesheets/application'

import { BulmaModal } from 'js_files/bulma_modal';
window.BulmaModal = BulmaModal;
import { BulmaTimeOutModal } from 'js_files/bulma_time_out_modal';
window.BulmaTimeOutModal = BulmaTimeOutModal;

import moment from 'moment';
window.moment = moment;

window.showFlash = () => {
  var b = document.querySelector('body');
  var dataFlashString = b.getAttribute('data-flash');
  var dataFlash = JSON.parse(dataFlashString);
  if (!(Object.keys(dataFlash).length === 0 && dataFlash.constructor === Object)) {
    if (dataFlash.hasOwnProperty('alert')) {
      toast({
        message: dataFlash.alert,
        position: "center",
        duration: 4000,
        type: "is-danger",
        opacity: 0.9,
        pauseOnHover: true,
        dismissible: true
      });
    }
    if (dataFlash.hasOwnProperty('notice')) {
      toast({
        message: dataFlash.notice,
        position: "center",
        duration: 4000,
        type: "is-success",
        opacity: 0.9,
        pauseOnHover: true,
        dismissible: true
      });
    }
  }
}

window.setFlashNotice = (text) => {
  document.querySelector('body').dataset.flash = JSON.stringify({ notice: text})
}

window.setFlashAlert = (text) => {
  document.querySelector('body').dataset.flash = JSON.stringify({ alert: text})
}

// bulma toast
import { toast } from "bulma-toast";
window.toast = toast;
document.addEventListener("turbolinks:load", () => {
  showFlash()
})


document.addEventListener("turbolinks:load", () => {
  window.locale = getLocale();
})


var formVal = require("js_files/formValidation/FormValidation")
window.FormValidation = formVal
FormValidation.plugins.Bulma = require("js_files/formValidation/plugins/Bulma")
FormValidation.plugins.AutoFocus = require("js_files/formValidation/plugins/AutoFocus")
FormValidation.locales.en_US = require("js_files/formValidation/locales/en_US")
FormValidation.locales.it_IT = require("js_files/formValidation/locales/it_IT")

require("trix")
require("@rails/actiontext")


// import { jsPDF } from "jspdf";
// window.jsPDF = jsPDF;



require("js_files/form_helpers")

require("js_files/booking")


// add form validation to guest
document.addEventListener("turbolinks:load", () => {
  if (document.getElementById('edit_guest')) {
    validateForm('edit_guest')
  }
})


// require("js_files/calendar")


// formatta il selettore della data per il framework Bulma
if (!window.formatDateSelector) {
  window.formatDateSelector = function() {
    let el = document.querySelectorAll('.ror-date-select');
    el.forEach((item, i) => {
      item.outerHTML = `<div class='select is-medium'>${item.outerHTML}</div>`;
    });
  }
}
document.addEventListener("turbolinks:load", () => {
  formatDateSelector()
})


document.addEventListener("turbolinks:load", () => {
  window.modalTicket = undefined // altrimenti rimane un modale segnato come is-active che non può essere riattivato fino al caricamento della pagina
  window.mdl = undefined
})



// fa funzionare il menu quando lo schermo è ridotto
document.addEventListener("turbolinks:load", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

  const input = document.getElementById('delete_account_input');
  const btn   = document.getElementById('delete_account_btn');
  if(input) {
    const email = input.dataset.email

    input.addEventListener('input', updateValue);

    function updateValue(e) {
      if (e.target.value==email) {
        btn.removeAttribute('disabled')
      } else {
        btn.setAttribute('disabled', '')
      }
    }
  }


  // Inside trix-content
  // always open links in a new tab
  var trixContent = document.querySelector(".trix-content a")
  if (trixContent) {
    trixContent.setAttribute('target', '_blank')
  }
  

})


window.populateTimeSelector = () => {
  var allDates        = JSON.parse(datesSelectorElement.dataset.arr)
  var numberOfTickets = parseInt(ticketsSelectorElement.value)

  // console.log(allDates)
  // console.log(numberOfTickets);

  // empty the list
  datesSelectorElement.innerHTML = ''
  // fill the list
  allDates.filter(value => {
    return value[1]>=numberOfTickets
  }).forEach(element => {
    var opt = document.createElement("option")
    opt.value = element[0]
    opt.text  = element[2]
    datesSelectorElement.add(opt, null)
  })
}



window.slidr = require("js_files/slidr")


window.getLocale = () => {
  return document.getElementsByTagName('body')[0].dataset.locale
}

window.toggleSessionxForm = function(event) {
  const el = document.querySelector("#sessionxForm")
  el.classList.toggle("is-hidden")
  document.querySelector("#sessionxErrorMsg").classList.add("is-hidden")
  event.preventDefault()
}

window.hideSessionxForm = function(event) {
  const el = document.querySelector("#sessionxForm")
  el.classList.add("is-hidden")
  document.querySelector("#sessionxErrorMsg").classList.add("is-hidden")
  event.preventDefault()
}


// window.SignaturePad = require("signature_pad")
import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;