class BulmaModal {
	constructor(selector) { // document.querySelector('#booking-modal').toggle('is-active')
		this.elem = document.querySelector(selector)
		this.close_data()
	}

	show() {
		if (!this.elem.classList.contains('is-active')) {
			this.elem.classList.toggle('is-active')
			this.on_show()
		}
	}

	close() {
		if (this.elem.classList.contains('is-active')) {
			this.elem.classList.toggle('is-active')
			this.on_close()
		}
	}

	close_data() {
		var modalClose = this.elem.querySelectorAll("[data-bulma-modal='close'], .modal-background")
		var that = this
		modalClose.forEach(function(e) {
			e.addEventListener("click", function() {

				that.elem.classList.toggle('is-active')

				var event = new Event('modal:close')

				that.elem.dispatchEvent(event);
			})
		})
	}

	on_show() {
		var event = new Event('modal:show')

		this.elem.dispatchEvent(event);
	}

	on_close() {
		var event = new Event('modal:close')

		this.elem.dispatchEvent(event);
	}

	addEventListener(event, callback) {
		this.elem.addEventListener(event, callback)
	}
}

export { BulmaModal };

// document.addEventListener("turbolinks:load", () => {
//   var btn = document.querySelector("#btn")
//   var mdl = new BulmaModal("#myModal")
//
//   btn.addEventListener("click", function () {
//   	mdl.show()
//   })
//
//   mdl.addEventListener('modal:show', function() {
//   	console.log("opened")
//   })
//
//   mdl.addEventListener("modal:close", function() {
//   	console.log("closed")
//   })
// })
