class BulmaTimeOutModal {
	constructor(selector) { // document.querySelector('#booking-modal').toggle('is-active')
		this.elem = document.querySelector(selector)
	}

	show() {
		if (this.elem && !this.elem.classList.contains('is-active')) {
			this.elem.classList.toggle('is-active')
			this.on_show()
		}
	}

	on_show() {
		var event = new Event('modal:show')
		this.elem.dispatchEvent(event);
	}
}

export { BulmaTimeOutModal };
