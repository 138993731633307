moment.updateLocale('en', {
  relativeTime : {
    future: "in %s",
    past:   "%s ago"
  }
});

moment.updateLocale('it', {
  relativeTime : {
    future: "tra %s",
    past:   "%s fa"
  }
});


is_time_out = (deadline) => {
  if (deadline!="") {
    let now = (new Date()).getTime()/1000
    return now>deadline
  } else {
    return false
  }
}


bookingUpdateRemainingTime = (deadline, translations, t_o) => {
  console.log(deadline=="");
  if (deadline=="") {
    document.querySelectorAll('.remaining-time').forEach((ele) => {
      ele.innerHTML = translations.time_out // ""
    })
    return true
  } else {
    if (t_o) {
      document.querySelectorAll('.remaining-time').forEach((ele) => {
        ele.innerHTML = translations.time_out  
      })
      return true
    } else {
      document.querySelectorAll('.remaining-time').forEach((ele) => {
        ele.innerHTML = translations.time_avalable + " " + moment.unix(deadline).fromNow()
      })
      return false
    }
  }
}


bookingStuffToExecute = (deadline, translations, t_o) => {
  bookingUpdateRemainingTime(deadline, translations, t_o)
  if(t_o && document.querySelector('#bookings-timeout-modal')) {
    (new BulmaTimeOutModal("#bookings-timeout-modal")).show()
  }
}


checkCouponValidity = (code) => {
  alert("checking")
}


handleCouponCheckboxClick = (cb) => {
  const coupon_container = cb.dataset.couponcontainer
  const coupon_field = cb.dataset.couponfield
  const target = document.getElementById(coupon_container).classList
  if (cb.checked) {
    target.remove('is-invisible')
    document.getElementById(coupon_field).focus()
  } else {
    target.add('is-invisible')
  }
}


handleCouponCodeVerification = (e) => {
  let verifyBtn = e.currentTarget
  let partId    = verifyBtn.dataset.partId
  let url1      = verifyBtn.dataset.url
  let startDt   = verifyBtn.dataset.eventDt

  // Elements
  // verifyBtn it's the button
  let inputCode = document.getElementById("participants_"+partId+"_coupon_code")
  let inputHelp = document.getElementById("participants_"+partId+"_coupon_msg")
  let inputIcon = verifyBtn.closest('.field.has-addons').querySelector('.icon')

  let code = inputCode.value
  let btnText = verifyBtn.dataset.btnText
  let btnTextWait = verifyBtn.dataset.btnTextWait


  if (code=="") {

  } else {
    verifyBtn.innerHTML = btnTextWait
    verifyBtn.setAttribute('disabled', true)
    inputIcon.innerHTML = ""
    inputIcon.classList.remove("has-text-success-dark", "has-text-danger-dark")
    inputHelp.innerHTML = ""
    inputCode.classList.remove("is-danger", "is-success")


    var url = new URL(url1)
    url.search = new URLSearchParams({ code: code, part: partId }).toString();

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let colorClass = ""
        if (data.result=="found") {
          colorClass = "success"
          inputIcon.innerHTML = "<i class='fas fa-check'></i>"
        } else {
          colorClass = "danger"
          inputIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>"
        }
        verifyBtn.innerHTML = btnText
        // verifyBtn.disabled = false
        verifyBtn.removeAttribute('disabled')

        inputIcon.classList.add("has-text-"+colorClass+"-dark")
        inputCode.classList.add("is-"+colorClass)
        inputHelp.innerHTML = "<span class='has-text-"+colorClass+"'>"+data.msg+"</span>"

      });
  }
}


document.addEventListener("turbolinks:load", () => {
  // click on 'Apply coupon'
  var applyCouponBtns = document.querySelectorAll('.apply-auto-coupon')
  applyCouponBtns.forEach((applyCouponBtn) => {
    applyCouponBtn.addEventListener('click', (e) => {
      e.preventDefault()
      let checkboxId = e.currentTarget.dataset.cb
      let checkbox = document.getElementById(checkboxId)
      checkbox.checked = 'checked'
      handleCouponCheckboxClick(checkbox)

      let inputFieldId = e.currentTarget.dataset.codefield
      let inputField = document.getElementById(inputFieldId)
      inputField.value = e.currentTarget.dataset.code

      document.querySelector("a.send-code-btn[data-part-id='"+e.currentTarget.dataset.participant+"']").click()
    })
  })


  // console.log('*****')
  if (typeof bookingInterval !== 'undefined') {
    clearInterval(bookingInterval)
  }

  // set and update remaining time advice
  let deadline     = document.querySelector('body').getAttribute('data-booking-deadline')
  const translations = JSON.parse(document.querySelector('body').getAttribute('data-booking-translations'))
  moment.locale(getLocale())

  t_o = is_time_out(deadline)
  bookingStuffToExecute(deadline, translations, t_o)
  window.bookingInterval = setInterval(function(){
    deadline     = document.querySelector('body').getAttribute('data-booking-deadline')
    t_o = is_time_out(deadline)
    bookingStuffToExecute(deadline, translations, t_o)
    if (t_o) {
      clearInterval(bookingInterval)
    }
  }, 5000);


  if (sel1 = document.querySelector('div[id ^= "error-for-"]')) {
    sel1.style.display = 'inline'
  }

  // button that copy data from buyer to participant
  var copyBuyerBtns = document.querySelectorAll('.copy-buyer-data')
  copyBuyerBtns.forEach((copyBuyerBtn) => {
    copyBuyerBtn.addEventListener('click', (e) => {
      let targetPrefix = e.currentTarget.dataset.target
      console.log(targetPrefix);
      document.getElementById(targetPrefix+'_first_name').value = document.getElementById('guest_first_name').value
      document.getElementById(targetPrefix+'_last_name').value = document.getElementById('guest_last_name').value
      document.getElementById(targetPrefix+'_email').value = document.getElementById('guest_email').value
    })
  })


  document.querySelectorAll('.coupon-cb').forEach((couponCb) => {
    handleCouponCheckboxClick(couponCb)
  })

  document.querySelectorAll('.coupon-cb').forEach((couponCb) => {
    couponCb.addEventListener('change', (e) => {
      let myself = e.currentTarget
      handleCouponCheckboxClick(myself)
    })
  })


  var sendCodeBtns = document.querySelectorAll('.send-code-btn')
  sendCodeBtns.forEach((sendCodeBtn) => {
    sendCodeBtn.addEventListener('click', (e) => {
      handleCouponCodeVerification(e)
    })
  })
})
